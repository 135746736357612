<template>
  <div>
    <div class="d-flex flex-no-wrap">
      <v-avatar class="ma-3" size="90">
        <PortraitImage :value="person" large />
      </v-avatar>
      <div>
        <v-card-title class="text-h5"
          ><PersonName :value="person" title
        /></v-card-title>
        <v-card-subtitle>
          {{ personSubtitle(person) }}
        </v-card-subtitle>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="fetchPortraitImage"
        v-if="person.portraitAvailable"
        ><v-icon left>mdi-download </v-icon> Portraitbild
      </v-btn>
    </div>
    <ContactEmail
      v-if="!hasRole('student')"
      class="mb-4"
      :person="person"
    ></ContactEmail>
    <ContactSchool class="mb-4" :person="person" />
    <ContactPersonal class="mb-4" :person="person" />
    <ContactParents :person="person" />
  </div>
</template>

<script>
import { personName, personSubtitle } from "common/utils/people.js";
import ContactEmail from "common/components/Person/ContactEmail.vue";
import ContactParents from "common/components/Person/ContactParents.vue";
import ContactPersonal from "common/components/Person/ContactPersonal.vue";
import ContactSchool from "common/components/Person/ContactSchool.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import PersonName from "common/components/PersonName.vue";

export default {
  name: "PersonContact",
  components: {
    ContactEmail,
    ContactParents,
    ContactPersonal,
    ContactSchool,
    PortraitImage,
    PersonName,
  },
  props: ["person"],
  watch: {
    person() {},
  },
  methods: {
    personSubtitle,
    async fetchData() {},
    async fetchPortraitImage() {
      const url = await this.apiBlob({
        path: "person/portrait/" + this.person.id,
      });
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = personName(this.person) + ".jpg";
        link.click();
      }
    },
  },
};
</script>
